import { get, post, setAuthHeader, put, deleteService } from './api.service.js';
import { URLS } from '../constants/const_services';
import { errorPopup, successPopup } from '../components/popup';
import { transformedFeriados, turnsWithPatients } from '../logic/utils.js';
/** *********************** POST  ************************/
export async function loginService (data) {
    try {
        const response = await post(URLS.LOGIN_URL, data, false);
        sessionStorage.setItem('user', JSON.stringify({
            token: response.data.token,
            userName: data.email,
            profCodToken: response.data.user.prof_cod
        }));
        sessionStorage.setItem('nameProf', JSON.stringify({
            name: response.data.user.name
        }));
        setAuthHeader(response.data.token);
        sessionStorage.setItem('session', 'true');
        return response;
    } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 401) {
            return false;
        }
        handleError(err);
        throw err;
    }
}

export async function postPacienteService (data) {
    if (!data) return false;
    if (data.cabecera && data.cabeceraCod) {
        data.cabecera = data.cabeceraCod.toString();
        delete data.cabeceraCod;
    }
    deleteEmptyFields(data);
    try {
        let response;
        if (data.id) {
            response = await put(URLS.PACIENTE_PUT_URL_ID + data.id, data);
        } else {
            response = await post(URLS.PACIENTE_POST_URL, data);
        }
        if (response.data.status) {
            successPopup();
            return response.data;
        }
    } catch (error) {
        if (!error.response) {
            errorPopup({ title: 'Error', text: 'No se pudo conectar al servidor' });
            return;
        }
        const errorData = error.response.data?.error;
        if (errorData) {
            const errorMessages = [];
            Object.keys(errorData).forEach(fieldName => {
                const fieldErrors = errorData[fieldName];
                fieldErrors.forEach(errorMessage => {
                    errorMessages.push(`${fieldName}: ${errorMessage}`);
                });
            });
            const errorMessageString = errorMessages.join(', ');
            error.response.errorMessageString = errorMessageString;
            errorPopup({ title: 'Error al crear el paciente.', text: errorMessageString });
            return;
        }
        handleError(error);
        return error;
    }
}

export async function postObraSocialService (data) {
    if (!data) return false;
}

export function postProfesionalService (data) {
    post(URLS.PROFESIONAL_POST_URL, data)
        .then((response) => {
            successPopup();
            return response.data.status;
        })
        .catch((error) => {
            console.log(error);
            handleError(error);
        });
}

export async function saveTurn (turn) {
    try {
        delete turn.obra_social_text;
        const response = await put(URLS.TURN_PUT_URL + '/' + turn.horario_id, turn);

        if (response.data.status) {
            successPopup('Turno guardado exitosamente');
            return response;
        } else {
            errorPopup('Error al guardar el turno');
            return null;
        }
    } catch (error) {
        console.log(error);
        handleError(error);
        return null;
    }
}

export async function saveIndividualTurn (turn) {
    try {
        const response = await post(URLS.INDIVIDUAL_TURN_POST_URL, turn);
        if (response.data.status) {
            successPopup();
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        handleError(error);
        return false;
    }
}

export async function postSchedule (schedule) {
    if (schedule.length === 0) return true;
    // eslint-disable-next-line camelcase
    const schedulesWithoutIds = schedule.map(({ horario_id, id, nombre_profesional, ...scheduleWithoutIds }) => scheduleWithoutIds);
    try {
        const response = await post(URLS.SCHEDULE_POST_URL, schedulesWithoutIds[0]); // Aca debemos iterar por todos, no uno solo
        return response.data.status;
    } catch (error) {
        console.log(error);
        handleError(error);
        return false;
    }
}

export function postLocalidadService (data) {
    post(URLS.LOCALIDAD_POST_URL, data)
        .then((response) => {
            if (response.data.status) {
                successPopup();
            }
        })
        .catch((error) => {
            console.log(error);
            handleError(error);
        });
}

export function saveTurnsFromSchedule (data) {
    post(URLS.TURN_POST_URL, data)
        .then((response) => {
            if (response.data.status && !response.data.errores) {
                successPopup();
            } else {
                errorPopup({ title: 'Turnos creados con errores', text: response.data.errores });
            }
        })
        .catch((error) => {
            console.log(error);
            errorPopup({ title: 'ERROR', text: error });
            handleError(error);
        });
}

export function saveFeriadoFromSchedule (data) {
    post(URLS.FERIADO_POST_URL, data)
        .then((response) => {
            if (response.data.status) {
                successPopup();
            }
        })
        .catch((error) => {
            console.log(error);
            handleError(error);
        });
}
export function createUser (user) {
    post(URLS.USER_POST_URL, user)
        .then((response) => {
            if (response.data.status) {
                successPopup();
                setTimeout(() => {
                    window.location.reload();
                }, 3500);
            }
        })
        .catch((error) => {
            console.log(error);
            handleError(error);
        });
}

export async function postHistorialClinico (data, turnId) {
    try {
        const response = await post(URLS.HISTORIAL_CLINICO_POST_URL, data);
        let updateTurnResponse = { data: { status: true } };
        if (turnId) {
            updateTurnResponse = await putTurn(turnId, { atendido: true, presente: true });
        }
        if (response.data.status && updateTurnResponse.data.status) {
            successPopup();
            return response.data;
        } else {
            errorPopup({ title: 'Se produjo un error al crear la historia clínica. Por favor, inténtelo de nuevo.', text: response.data.errores });
            return false;
        }
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error.prof_cod) {
            errorPopup({ title: 'Error', text: 'Usted no tiene permiso para realizar esta operación' });
            return;
        }
        handleError(error);
    }
}

export async function saveSchedules (schedules) {
    const postSchedules = schedules.filter((schedule) => schedule.horario_id === -1);
    const putSchedules = schedules.filter((schedule) => schedule.horario_id !== -1);
    const callPost = await postSchedule(postSchedules);
    const callPut = await putSchedule(putSchedules);
    if (callPost && callPut) {
        successPopup();
    } else {
        errorPopup();
        console.log(callPost, callPut);
    }
}

/** *********************** GET ************************/
export async function getLocalidadesService () {
    try {
        const json = await get(URLS.GET_CP_URL);
        const localidades = sortByAlphabetic(json.data.codigos_postales
            .map((cp) => ({ ide: cp.id, text: cp.ciudad + ' - ' + cp.provincia, codigo: cp.codigo })));
        return localidades;
    } catch (error) {
        console.log(error);
        return [];
    }
}

export async function getAllPacientesService () {
    try {
        const json = await get(URLS.GET_ALL_PACIENTES);
        return {
            enabled: true,
            pacientes: sortByAlphabetic(json.data.pacientes)
        };
    } catch (error) {
        console.log(error);
        return {
            enabled: false
        };
    }
}

export async function getSearchPacientesService (search) {
    try {
        const url = `${URLS.GET_PACIENTE_SEARCH}?search=${search}`;
        const json = await get(url);
        return {
            enabled: true,
            pacientes: sortByAlphabetic(json.data.pacientes)
        };
    } catch (error) {
        return {
            enabled: false
        };
    }
}

export async function getAllProfesionalesService () {
    try {
        const json = await get(URLS.GET_ALL_PROFESIONALES);
        return sortByAlphabetic(json.data.profesionales.map((user) => ({ id: user.id, text: user.Apellido + ' ' + user.Nombre, codigo: user.Codigo })));
    } catch (error) {
        console.log(error);
        return [];
    }
}

export async function getAllProfesionalesActivosService () {
    try {
        const json = await get(URLS.GET_ALL_PROFESIONALES_ACTIVOS);
        return sortByAlphabetic(json.data.profesionales.map((user) => ({ id: user.id, text: user.Apellido + ' ' + user.Nombre, codigo: user.Codigo })));
    } catch (error) {
        console.log(error);
        return [];
    }
}

export async function getTurnsByDate (date) {
    try {
        const response = await get(URLS.GET_TURNO_BY_DATE + '?fecha=' + date);
        const formattedTurns = turnsWithPatients(response.data.turnos);
        return formattedTurns;
    } catch (error) {
        console.log(error);
        return [];
    }
}

export async function getTurnsByDateProfessional (date, profesional) {
    try {
        const response = await get(URLS.GET_TURNO_BY_DATE_PROFESSIONAL + '?fecha=' + date + '&prof_cod=' + profesional);
        return response.data.turnos || [];
    } catch (err) {
        if (err.response.status === 204) {
            return [{ id: -1, text: 'No se encontraron horarios disponibles' }];
        } else {
            console.error(err);
            return [];
        }
    }
}

export async function getTurnsByDateProfessionalLibre (date, profesional) {
    try {
        const response = await get(URLS.GET_TURNO_BY_DATE_PROFESSIONAL_LIBRE + '?fecha=' + date + '&prof_cod=' + profesional);
        return (
            response.data.turnos.map((t) => ({ id: t.id, text: t.hora }))
        );
    } catch (err) {
        if (err.response.status === 204) {
            return [{ id: -1, text: 'No se encontraron horarios disponibles' }];
        } else {
            console.error(err);
        }
    }
}

export async function getAllObrasocialesNotFormat () {
    try {
        const json = await get(URLS.GET_OBRAS_SOCIALES);
        return json.data;
    } catch (error) {
        console.error('Error al obtener las obras sociales:', error);
        return [];
    }
}

export async function getAllObrasociales () {
    try {
        const json = await get(URLS.GET_OBRAS_SOCIALES);
        const obrasSociales = json.data.obras_sociales.map((os) => {
            let text = os.descripcion;
            if (os.activa === 0) {
                text += ' - NO ACTIVA';
            }
            return { id: os.id, text: text, codigo: os.codigo };
        });
        return obrasSociales;
    } catch (error) {
        console.log(error);
        return [];
    }
}

export async function getObraSocialByCodigo (codigo) {
    try {
        const json = await get(URLS.GET_OBRA_SOCIAL_BY_COD + codigo);
        return json.data.obra_social;
    } catch (error) {
        console.log(error);
        return [];
    }
}

export async function getHorariosByProfesional (codigo) {
    try {
        const json = await get(URLS.GET_HORARIOS_BY_PROFESSIONAL + codigo);
        return getTableHorarios(json.data.horarios);
    } catch (error) {
        console.log(error);
        return [];
    }
}

// eslint-disable-next-line no-unused-vars
export async function getProfesionalByCodigo (codigo) {
    try {
        const json = await get(URLS.GET_PROFESIONAL_BY_COD + codigo);
        return json.data.user;
    } catch (error) {
        console.log(error);
        errorPopup({ title: 'Error', text: error.response.data.error });
    }
}

export async function getFeriados () {
    try {
        const json = await get(URLS.GET_FERIADOS);
        const turnos = json?.data?.turnos;
        if (Array.isArray(turnos)) {
            return transformedFeriados(json.data.turnos);
        } else {
            return [];
        }
    } catch (error) {
        console.log(error);
        return [];
    }
}

export async function getDispTurns (profCod) {
    try {
        const json = await get(URLS.GET_TURNOS_DISP_NO_DISP + Number(profCod));
        return json.data;
    } catch (error) {
        console.log(error);
        return [];
    }
}

/** ************************ PUT *************************/
export async function putSchedule (schedules) {
    if (schedules.length === 0) return true;
    try {
        const response = await put(URLS.SCHEDULE_PUT_URL, schedules, true, true);
        return response.data.status;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export async function putObraSocial (id, data) {
    try {
        const response = await put(URLS.PUT_OBRAS_SOCIALES + '/' + id, data);
        return response.data.status;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function putTurn (id, data, dontShowPopup = false) {
    try {
        const response = await put(URLS.TURN_PUT_URL + '/' + id, data);
        if (response.data.status) {
            if (!dontShowPopup) {
                successPopup();
            }
            return response;
        } else {
            errorPopup({ title: 'Se ha producido un error al actualizar el turnero. Por favor, inténtelo nuevamente', text: response.data.errores });
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}

export async function putHC (id, data) {
    try {
        const response = await put(URLS.HC_PUT_URL + id, data);
        return response.data.status;
    } catch (error) {
        console.log(error);
        handleError(error);
        return false;
    }
}

export async function getHistorialClinico (idPaciente) {
    try {
        const json = await get(URLS.GET_HISTORIAL_CLINICO + idPaciente);
        return json.data.hc;
    } catch (error) {
        console.log(error);
    }
}

export async function getPacienteById (id) {
    try {
        const json = await get(URLS.GET_PACIENTE_BY_ID + id);
        return json.data.paciente;
    } catch (error) {
        console.log(error);
    }
}
/** ************************ DELETE *************************/
export function deleteProfesional (id) { // NO es un delete , es un put con daTurnos = 0
    const data = {
        daTurnos: 0
    };
    put(URLS.DELETE_PROFESIONAL + id, data)
        .then((response) => {
            if (response.data.status) {
                successPopup('Profesional eliminado con éxito');
            }
        })
        .catch((error) => {
            console.log(error);
            errorPopup({ title: 'Error', text: error.response.data.error });
        });
}

export async function deleteHorarioService (id) {
    try {
        const response = await deleteService(URLS.DELETE_HORARIO + id, true);
        return response.data.status;
    } catch (error) {
        console.log(error);
        handleError(error);
    }
}

export async function deleteTurn (id) {
    try {
        const response = await deleteService(URLS.DELETE_TURNO + id, true);
        if (response.data.status) {
            successPopup('Turno eliminado exitosamente');
        }
        return response.data.status;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export async function getUserByName (name) {
    try {
        const json = await get(URLS.GET_USER_BY_NAME + '?name=' + name);
        return json.data.user;
    } catch (err) {
        console.log(err);
    }
}
// CUANDO SE IMPLEMENTE USERBYNAME, ELIMINAR ESTA FUNCIÓN
export async function getUserByNamev2 (name) {
    try {
        const response = await true;
        return response;
    } catch (err) {
        console.log(err);
    }
}

/** ******************UTILS *****************/

function sortByAlphabetic (list) {
    return list.sort((a, b) => {
        if (a.text > b.text) {
            return 1;
        }
        if (a.text < b.text) {
            return -1;
        }
        return 0;
    });
}

function getTableHorarios (horarios) {
    return horarios.map(
        (h, index) => ({
            id: index,
            prof_cod: horarios.prof_cod,
            nombre_profesional: h.profesional.Apellido + ', ' + h.profesional.Nombre,
            horario_id: h.id,
            dia: h.dia,
            desde: h.desde,
            hasta: h.hasta,
            tiempo: h.tiempo
        })
    );
}

function deleteEmptyFields (data) {
    Object.keys(data).forEach((key) => {
        if (data[key] === '' || data[key] === null || data[key] === undefined) {
            delete data[key];
        }
    });
}

function handleError (error) {
    if (!error.response) {
        errorPopup({ title: 'Error', text: 'No se pudo conectar con el servidor' });
        return;
    }

    const { message: errors, error: errorBack } = error.response.data;

    const showErrorsAsList = (errorObj) => {
        let errorHtml = '<ul>';
        Object.values(errorObj).forEach((e) => {
            errorHtml += `<li>${e}</li>`;
        });
        errorHtml += '</ul>';
        errorPopup({ title: 'Error', html: errorHtml });
    };

    if (errors && typeof errors !== 'string') {
        showErrorsAsList(errors);
    } else if (errors && typeof errors === 'string') {
        errorPopup({ title: 'Error', text: errors });
    }

    if (errorBack && typeof errorBack !== 'string') {
        showErrorsAsList(errorBack);
    } else if (errorBack && typeof errorBack === 'string') {
        errorPopup({ title: 'Error', text: errorBack });
    }
}
