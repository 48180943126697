import React from 'react';
import AddLocalidad from '../parts/Localidad';
import CreateTurns from '../parts/CreateTurns';

export const addPacienteConstants = {
    LABEL_NOMBRE_INPUT: 'Nombre completo (Apellido y Nombre) *',
    LABEL_TELEFONO_INPUT: 'Teléfono *',
    LABEL_CELULAR_INPUT: 'Celular (sin el 15)',
    LABEL_SEXO_INPUT: 'Sexo *',
    LABEL_FNAC_INPUT: 'Fecha de nacimiento *',
    LABEL_EMAIL_INPUT: 'Email',
    LABEL_DNI_INPUT: 'DNI *',
    LABEL_DIRECCION_INPUT: 'Direccion *',
    LABEL_LOCALIDAD_INPUT: 'Localidad',
    LABEL_OSPRINCIPAL_INPUT: 'Obra social principal',
    LABEL_PLAN_INPUT: 'Plan',
    LABEL_OSSECUNDARIA_INPUT: 'Obra social secundaria',
    LABEL_NUM_AFILIADO_INPUT: 'Número de afiliado',
    LABEL_PROF_INPUT: 'Médico Cabecera',
    LABEL_ULTIMAVISITA_INPUT: 'Última visita',
    LABEL_ANTECEDENTES_INPUT: 'Antecedentes',
    VALID_INPUTS_PACIENT: [
        'Nombre',
        'FechaNacimiento',
        'Direccion',
        'Telefono'
    ],
    emptyInputs: {
        Nombre: '',
        FechaNacimiento: false,
        Genero: '',
        Direccion: '',
        CodPos: null,
        Telefono: '',
        Celular: '',
        Mail: '',
        TipoDocumento: 1,
        NumDocumento: '',
        PlanText: '',
        Plan2Text: '',
        Plan: null,
        Plan2: null,
        NumAfiliado: '',
        Empres: null,
        Cabecera: null,
        cabeceraText: '',
        UltimaVisita: '',
        Antecedentes: ''
    }
};

export const SEXO = [
    {
        ide: 'femenino',
        text: 'Femenino'
    },
    {
        ide: 'masculino',
        text: 'Masculino'
    },
    {
        ide: 'otro_sexo',
        text: 'Otro'
    }
];

export const Popups = {
    OPCIONES_POPUP_PACIENTE: {
        title: 'Está a punto de añadir un nuevo paciente.',
        text: '¿Desea continuar?'
    },
    OPCIONES_POPUP_PROFESIONAL: {
        title: 'Está a punto de añadir un nuevo profesional',
        text: '¿Desea continuar?'
    },
    ADD_LOCACION: {
        title: 'Nueva localidad',
        form: <AddLocalidad />
    },
    CREATE_TURNS: {
        title: 'Nuevos turnos',
        form: <CreateTurns />
    },
    DELETE_PROFESIONAL: {
        title: 'Está a punto de eliminar un profesional',
        text: '¿Está seguro de que desea continuar?'
    },
    DELETE_PACIENTE: {
        title: 'Está a punto de eliminar un paciente.',
        text: '¿Está seguro que desea continuar? Esta acción no podrá revertirse.'
    },
    ERROR_POPUP_PROFESIONAL: {
        title: 'Faltan campos por rellenar',
        text: 'Por favor rellene todos los campos'
    }

};

export const AddProfesionalConstants = {
    CATEGORIAS: [
        {
            ide: 0,
            text: 'Categoría n° 1'
        },
        {
            ide: 1,
            text: 'Categoría n° 2'
        },
        {
            ide: 2,
            text: 'Categoría n° 3'
        }
    ],
    EMPTY_PROFESIONAL: {
        Codigo: '',
        Nombre: '',
        Apellido: '',
        tipoDocumento: 1,
        NumDocumento: '',
        Telefono: '',
        FechaNacimiento: false,
        Genero: '',
        Matricula: '',
        Cuit: '',
        Direccion: '',
        Mail: ''
        // Categoria: "", TODO: CONSULTAR POR ESTOS CAMPOS
        // ProfesionalTurno: ""
    }

};

export const turnsConstants = {
    schedule: [
        { id: 1, text: '08:00' },
        { id: 2, text: '08:15' },
        { id: 3, text: '08:30' },
        { id: 4, text: '08:45' },
        { id: 5, text: '09:00' },
        { id: 6, text: '09:15' },
        { id: 7, text: '09:30' },
        { id: 8, text: '09:45' },
        { id: 9, text: '10:00' },
        { id: 10, text: '10:15' },
        { id: 11, text: '10:30' },
        { id: 12, text: '10:45' },
        { id: 13, text: '11:00' },
        { id: 14, text: '11:15' },
        { id: 15, text: '11:30' },
        { id: 16, text: '11:45' },
        { id: 17, text: '12:00' },
        { id: 18, text: '12:15' },
        { id: 19, text: '12:30' },
        { id: 20, text: '12:45' },
        { id: 21, text: '13:00' },
        { id: 39, text: '13:15' },
        { id: 40, text: '13:30' },
        { id: 41, text: '13:45' },
        { id: 42, text: '14:00' },
        { id: 43, text: '14:15' },
        { id: 44, text: '14:30' },
        { id: 45, text: '14:45' },
        { id: 46, text: '15:00' },
        { id: 47, text: '15:15' },
        { id: 48, text: '15:30' },
        { id: 49, text: '15:45' },
        { id: 22, text: '16:00' },
        { id: 23, text: '16:15' },
        { id: 24, text: '16:30' },
        { id: 25, text: '16:45' },
        { id: 26, text: '17:00' },
        { id: 27, text: '17:15' },
        { id: 28, text: '17:30' },
        { id: 29, text: '17:45' },
        { id: 30, text: '18:00' },
        { id: 31, text: '18:15' },
        { id: 32, text: '18:30' },
        { id: 33, text: '18:45' },
        { id: 34, text: '19:00' },
        { id: 35, text: '19:15' },
        { id: 36, text: '19:30' },
        { id: 37, text: '19:45' },
        { id: 38, text: '20:00' }
    ]

};

export const DAYS = [
    { id: 'Lunes', text: 'Lunes' },
    { id: 'Martes', text: 'Martes' },
    { id: 'Miércoles', text: 'Miércoles' },
    { id: 'Jueves', text: 'Jueves' },
    { id: 'Viernes', text: 'Viernes' },
    { id: 'Sabado', text: 'Sábado' }
];
export const SCHEDULE_CELLS = [
    { field: 'nombre_profesional', headerName: 'Profesional' },
    { field: 'dia', headerName: 'Día' },
    { field: 'desde', headerName: 'Desde' },
    { field: 'hasta', headerName: 'Hasta' },
    { field: 'tiempo', headerName: 'Tiempo', options: { type: 'number' } }
];
export const EMPTY_SCHEDULE = {
    prof_cod: -1,
    nombre_profesional: '',
    horario_id: -1,
    dia: '',
    desde: null,
    hasta: null,
    tiempo: ''
};

export const motivos = [
    { id: 1, ide: 'consulta', text: 'Consulta' },
    { id: 2, ide: 'reConsulta', text: 'Re consulta' },
    { id: 3, ide: 'Control', text: 'Control' },
    { id: 4, ide: 'ctrlCirugia', text: 'Ctrl. Cirugia' },
    { id: 5, ide: 'estudio', text: 'Estudios' },
    { id: 6, ide: 'certificado', text: 'Certificados' },
    { id: 7, ide: 'otros', text: 'Otros' }
];
export const emptyHistorial = {
    motivo: '',
    observ: ''
};

export const emptyTurn = {
    fecha: new Date(),
    paciente_id: '',
    horario_id: '',
    observ: '',
    obra_social: '',
    obra_social_text: ''
};
